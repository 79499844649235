import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/vehicle-details-view",
    name: "vehicle-details-view",
    component: () => import("@/views/VehicleDetailsView.vue"),
  },
  {
    path: "/vehicle-checkout-view",
    name: "vehicle-checkout-view",
    component: () => import("@/views/VehicleCheckoutView.vue"),
  },
  {
    path: "/top-destinations",
    name: "top-destinations",
    component: () => import("@/views/TopDestinationsView.vue"),
  },
  {
    path: "/flight-search-result",
    name: "flight-search-result",
    component: () => import("@/views/FlightSearchResultView.vue"),
  },
  {
    path: "/flight-booking-submission",
    name: "flight-booking-submission",
    component: () => import("@/views/FlightBookingSubmission.vue"),
  },
  {
    path: "/hotel-search",
    name: "hotel-search",
    component: () => import("@/views/HotelSearchView.vue"),
  },
  {
    path: "/hotel/:id",
    name: "hotel-detail",
    component: () => import("@/views/HotelDetailsView.vue"),
  },

  {
    path: "/room-booking",
    name: "room-booking",
    component: () => import("@/views/RoomBookingView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/Login.vue"),
  },
  {
    path: "/success/:id",
    name: "success",
    component: () => import("@/components/globals/SuccessView.vue"),
  },
  {
    path: "/cancel",
    name: "cancel",
    component: () => import("@/components/globals/CancelView.vue"),
  },

  {
    path: "/create-password",
    name: "create-password",
    component: () => import("@/views/account/Password.vue"),
  },
  {
    path: "/otp-verify",
    name: "otp-verify",
    component: () => import("@/views/account/OtpVerification.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPasswordView.vue"),
  },
  {
    path: "/verify-otp",
    name: "verify-otp",
    component: () => import("@/views/VerifyOtpView.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPasswordView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard/hotel-booking",
    name: "hotel-booking",
    component: () => import("@/views/HotelBookingView.vue"),
  },
  {
    path: "/dashboard/flight-booking",
    name: "flight-booking",
    component: () => import("@/views/FlightBookingView.vue"),
  },
  {
    path: "/dashboard/tour-booking",
    name: "tour-booking",
    component: () => import("@/views/TourBookingView.vue"),
  },
  {
    path: "/dashboard/booking-history",
    name: "booking-history",
    component: () => import("@/views/BookingHistoryView.vue"),
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: () => import("@/views/MyProfileView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/ChangePasswordView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("@/views/WalletView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/NotificationView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/PrivacyPolicyView.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/become-vendor",
    name: "become-vendor",
    component: () => import("@/views/BecomeVendorView.vue"),
  },
  {
    path: "/terms-service",
    name: "terms-service",
    component: () => import("@/components/globals/TermsAndConditions.vue"),
  },
  {
    path: "/:cathAll(.*)",
    name: "NotFound",
    component: () => import("@/components/NotFound.vue"),
  },
];

import { RouteLocationNormalized } from "vue-router";

const scrollBehavior = (
  to: RouteLocationNormalized,
  _from: any,
  savedPosition: any
) => {
  return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
};

declare module "vue-router" {
  interface RouteMeta {
    scrollPos?: {
      top: number;
      left: number;
    };
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
  linkActiveClass: "red",
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY);
  const routeIsProtected = to.matched.some(
    (record) => record.meta.requiresAuth
  );
  if (routeIsProtected && !localStorage.getItem("userToken")) {
    userStore.isLogin = false;
    next("/login");
    return;
  }
  if (localStorage.getItem("userToken")) {
    userStore.isLogin = true;
    next();
    return;
  }
  next();
});

export default router;
